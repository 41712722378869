<template>
  <div class="order_detail_page_wrap" v-if="data" >
    <div class="order_status_wrap" >
      <div style="color: #FFC000" v-if="data.status == 1" >待发货</div>
      <div style="color: #03CFAF" v-if="data.status == 2">已发货</div>
      <div style="color: #474747" v-if="data.status == 4">已退款</div>
    </div>
    <div class="space" ></div>
    <div class="goods_list" >
      <div class="goods_item" v-for="(item,index) in data.goodsInfo" :key="index" >
        <img :src="item.image" class="goods_img" />
        <div class="goods_right" >
          <div class="goods_title" >{{ item.storeName }}</div>
          <div class="goods_bottom" >
            <div class="goods_price" ><span>￥</span>{{ item.price }}</div>
            <div class="goods_number" >x{{ item.num }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="space" ></div>
    <div class="order_data_wrap" >
      <div class="order_data_item" >
        <div>订单编号</div>
        <div>{{ data.id }}</div>
      </div>

      <div class="order_data_item" >
        <div>收货人</div>
        <div>{{ data.receiveName }}</div>
      </div>

      <div class="order_data_item" >
        <div>联系方式</div>
        <div>{{ data.receiveTel }}</div>
      </div>

      <div class="order_data_item" style="align-items: flex-start" >
        <div>收货地址</div>
        <div>
          {{ data.address }}
        </div>
      </div>
    </div>
    <div class="space" ></div>
    <div class="info_content" >
      <div>订单总额</div>
      <div>¥{{ data.amount }}
<!--        <span style="color: #FFC000">（含运费¥8.00）</span>-->
      </div>
    </div>
    <div class="space" ></div>
    <div class="info_content" >
      <div>支付方式</div>
      <div>微信</div>
    </div>
<!--    <div class="space" ></div>
    <div class="info_content" >
      <div>折扣金额</div>
      <div style="color: #FFC000">-¥8.00</div>
    </div>-->
    <div class="space" ></div>
    <div class="info_content" >
      <div>下单时间</div>
      <div>{{ data.payTime }}</div>
    </div>
    <div class="space" v-if="data.sendTime" ></div>
    <div class="info_content" v-if="data.sendTime" >
      <div>发货时间</div>
      <div>{{ data.sendTime }}</div>
    </div>
<!--    <div class="space" ></div>
    <div class="info_content" >
      <div>完成时间</div>
      <div>2020-10-19 03:22:34</div>
    </div>-->
    <div class="space" v-if="data.trackingNumber" ></div>
    <div class="info_content" v-if="data.trackingNumber" >
      <div>物流信息</div>
      <div>
        {{ data.trackingNumber }}
        <div class="copy" @click="handleCopy(data.trackingNumber)" >复制</div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="less" >
@import "index";
</style>
<script>
import { Toast } from 'vant'
export default {
  name: 'detail',
  data() {
    return {
      data: null
    }
  },
  methods: {
    handleCopy(content){
      this.copyContent(content)
    },
    async copyContent (content) {
      const that = this
      // 复制结果
      let copyResult = true
      // 设置想要复制的文本内容
      const text = content || '复制内容为空哦~';
      // 判断是否支持clipboard方式
      if (!!window.navigator.clipboard) {
        // 利用clipboard将文本写入剪贴板（这是一个异步promise）
        await window.navigator.clipboard.writeText(text).then((res) => {
          console.log('复制成功');
          Toast('复制成功')
        }).catch((err) => {
          console.log('复制失败--采取第二种复制方案', err);
          // clipboard方式复制失败 则采用document.execCommand()方式进行尝试
          copyResult =  that.copyContent2(text)
        })
      } else {
        // 不支持clipboard方式 则采用document.execCommand()方式
        copyResult =  that.copyContent2(text)
      }
      // 返回复制操作的最终结果
      return copyResult;
    },
    copyContent2 (text) {
      // 复制结果
      let copyResult = true
      // 创建一个input元素
      let inputDom = document.createElement('textarea');
      // 设置为只读 防止移动端手机上弹出软键盘
      inputDom.setAttribute('readonly', 'readonly');
      // 给input元素赋值
      inputDom.value = text;
      // 将创建的input添加到body
      document.body.appendChild(inputDom);
      // 选中input元素的内容
      inputDom.select();
      // 执行浏览器复制命令
      // 复制命令会将当前选中的内容复制到剪切板中（这里就是创建的input标签中的内容）
      // Input要在正常的编辑状态下原生复制方法才会生效
      const result = document.execCommand('copy')
      // 判断是否复制成功
      if (result) {
        Toast('复制成功')
      } else {
        Toast('复制失败')
        copyResult = false
      }
      // 复制操作后再将构造的标签 移除
      document.body.removeChild(inputDom);
      // 返回复制操作的最终结果
      return copyResult;
    }
  },
  mounted() {
    this.data = JSON.parse(this.$route.query.data)
    console.log({...this.data})
  }
}
</script>
